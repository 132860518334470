// src/components/SharedComponents/Sidebars/SidebarWishlist.tsx

'use client';

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useGlobalContext from "@/hooks/use-context";
import { remove_wishlist_product } from "@/redux/slices/wishlistSlice";
import { RootState } from "@/redux/store";
import Image from "next/image";
import Link from "next/link";

const SidebarWishlist = () => {
  const { sideWishlistOpen, setSideWishlistOpen } = useGlobalContext();
  const dispatch = useDispatch();

  // Access wishlistProducts from the Redux store
  const wishlistProducts = useSelector(
    (state: RootState) => state.wishlist.wishlistProducts
  );

  // Calculate total price
  const totalPrice = Array.isArray(wishlistProducts)
    ? wishlistProducts.reduce(
        (total, product) => total + (parseFloat(product.price) || 0),
        0
      )
    : 0;

  return (
    <div className="fix">
      <div
        className={`sidebar-action sidebar-wishlist ${
          sideWishlistOpen ? 'wishlist-open' : ''
        }`}
      >
        <button
          onClick={() => setSideWishlistOpen(!sideWishlistOpen)}
          className="close-sidebar"
        >
          Close<i className="fal fa-times"></i>
        </button>
        <h4 className="sidebar-action-title">Wishlist</h4>
        <div className="sidebar-action-list">
          {Array.isArray(wishlistProducts) && wishlistProducts.length > 0 ? (
            wishlistProducts.map((item, index) => {
              const productPrice = parseFloat(item.price) || 0;
              return (
                <div key={index} className="sidebar-list-item">
                  <div className="product-image pos-rel">
                    <Link href={`/shop/product/${item.id}`}>
                      <Image
                        width={150}
                        height={150}
                        src={
                          item.product_image && item.product_image.length
                            ? `https://partspluseg.com/${item.product_image[0].image}`
                            : "/default-image.png"
                        }
                        alt={item.name}
                        className="object-contain"
                        onError={(e: any) => {
                          (e.target as HTMLImageElement).onerror = null; // Prevent infinite loop
                          (e.target as HTMLImageElement).src = "/default-image.png";
                        }}
                      />
                    </Link>
                  </div>
                  <div className="product-desc">
                    <div className="product-name">
                      <Link href={`/shop/product/${item.id}`}>{item.name}</Link>
                    </div>
                    <div className="product-pricing">
                      <span className="price-now">{productPrice.toFixed(2)} EGP</span>
                    </div>
                    <button
                      onClick={() => dispatch(remove_wishlist_product(item))}
                      className="remove-item"
                    >
                      <i className="fal fa-times"></i>
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-center pt-20">Your Wishlist Is Empty</p>
          )}
        </div>
        {Array.isArray(wishlistProducts) && wishlistProducts.length > 0 && (
          <>
            <div className="product-price-total">
              <span>Subtotal:</span>
              <span className="subtotal-price">{totalPrice.toFixed(2)} EGP</span>
            </div>
            <div className="sidebar-action-btn">
              <Link
                onClick={() => setSideWishlistOpen(false)}
                href="/wishlist"
                className="fill-btn"
              >
                View Wishlist
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SidebarWishlist;
